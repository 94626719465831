/**
 * Delays the execution of the passed function until the current call stack is empty.
 * 
 * @param fn - Function that will be deferred.
 * @param immediate - If true, the function will be executed inmediately.
 * @returns a promise that resolves with the result of the deferred function.
 */
const defer = function (fn, immediate = false) {
    const deferredFn = function (...args) {
        const self = this;

        return new Promise((resolve) => {
            deferringFunction(() => {
                const res = fn.apply(self, args);
                resolve(res);
            });
        });
    };

    return immediate ? deferredFn() : deferredFn;
}

const deferringFunction = (callback) => {
    if(window?.requestAnimationFrame) {
        // Vue has issues with requestAnimationFrame, so we need to chain two calls.
        window.requestAnimationFrame(() => window.requestAnimationFrame(callback));
    } else {
        setTimeout(callback);
    }
}

export { defer };
