/**
 * Enum for btn plus disabled types.
 * @readonly
 * @enum type of plus disabled
 *  */
const BTN_PLUS_DISABLED_TYPES = Object.freeze({
  MAX_UNITS: 'checkout.errors.CRT009'
});

export default BTN_PLUS_DISABLED_TYPES;
