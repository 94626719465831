import UtilsCookies from "CommonUtils/cookies.js";

export default {
  inject: {
    'richRelevance': {
      default: () => ({
        hasRichRelevance: false
      })
     }
  },
  computed: {    
    ...mapGetters('cart', {      
      isLogged: 'isLogged',      
      getUser: 'getUser',      
    }),
  },
  methods: {
    sendAddToCartEventToRR(productsAddedToCart) {                  
      const cookiesPolicy = UtilsCookies.getCookieValue('cookiesPolicy');
      let userAcceptedCookiesPolicy = false;
      if ((cookiesPolicy === '1' || cookiesPolicy?.charAt(2) === '1')) {
        userAcceptedCookiesPolicy = true;
      }
      if (userAcceptedCookiesPolicy && this.richRelevance?.hasRichRelevance && productsAddedToCart?.length) {
        setTimeout(() => {
          const userId = this.isLogged && this.getUser?.id;
          const sessionId = UtilsCookies.getCookieValue("session_id");
          const rrApiCaller = new window.r3_common();
          rrApiCaller.setApiKey(this.richRelevance.api_key);
          rrApiCaller.setBaseUrl(this.richRelevance.frontendURL);
          rrApiCaller.setSessionId(sessionId);
          if (userId) {
            rrApiCaller.setUserId(userId);
          }        
          rrApiCaller.addPlacementType('add_to_cart_page');
          const rrAddToCart = new window.r3_addtocart();
          productsAddedToCart.forEach(product => {
            rrAddToCart.addItemIdToCart(product.data_layer.code_a, product.data_layer.variant); 
          })        
          window.rr_flush_onload();
          window.r3(); 
        }, 0);
      }
    },
  },
};