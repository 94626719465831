<template>
  <section
    :aria-label="$t('product.price.title')"
    class="price">
    <div
      class="price--main"
      :class="{ 'price-with-recommended': mustShowRecommendedPrice }">
      <div
        v-if="hasPriceRange"
        class="price--range"
        :class="{
          'price--range--discount': item.discount,
          'price--with_color': hasColorSelector,
          'price-range-with-recommended': mustShowRecommendedPrice,
        }">
        {{ $t('product.price.from') }}
      </div>
      <div
        v-if="item.discount"
        class="price-container">
        <div
          itemprop="offers"
          itemtype="http://schema.org/Offer"
          itemscope>
          <meta
            itemprop="priceCurrency"
            content="EUR" />
          <meta
            itemprop="price"
            :content="item.unit_sale_price" />
          <meta
            itemprop="lowPrice"
            :content="item.unit_sale_price" />
          <meta
            itemprop="highPrice"
            :content="item.unit_price" />
        </div>
        <span
          :aria-label="$t('product.price.sale_price')"
          :class="mainPriceStyle">
          {{ formattedUnitSalePrice }}
        </span>
        <span
          v-if="!mustShowRecommendedPrice"
          :aria-label="$t('product.price.original_price')"
          class="price-unit--original"
          :class="className">
          {{ formattedUnitPrice }}
        </span>
        <span
          v-if="!mustShowRecommendedPrice"
          :aria-label="$t('product.price.discount')"
          class="price-discount">
          {{ item.discount }}%
        </span>
      </div>
      <div
        v-else
        class="price-container">
        <div
          itemprop="offers"
          itemtype="http://schema.org/Offer"
          itemscope>
          <meta
            itemprop="priceCurrency"
            content="EUR" />
          <meta
            itemprop="price"
            :content="priceWithoutDiscount" />
          <meta
            itemprop="highPrice"
            :content="priceWithoutDiscount" />
          <meta
            itemprop="lowPrice"
            :content="item.unit_price" />
        </div>
        <span
          :aria-label="$t('product.price.sale_price')"
          class="price-unit--normal"
          :class="className">
          {{ formattedPriceWithoutDiscount }}
        </span>
      </div>
      <div
        v-if="item.pumOrPackComposition"
        class="price-pum">
        {{ item.pumOrPackComposition }}
      </div>
      <div
        v-if="hasRecommendedPrice"
        :aria-label="$t('product.price.recommended_price')"
        class="price-recommended"
        :class="className">
        {{ $t('product.price.recommended_price') }}
        {{ formattedUnitPrice }}
      </div>
    </div>
  </section>
</template>

<script>
import PriceTypes from 'Utils/schemas/price.js';
import usePriceFormat from 'Composables/usePriceFormat.js';

export default {
  props: {
    className: {
      type: String,
      default: '',
    },
    hasPriceRange: {
      type: Boolean,
      default: false,
    },
    hasColorSelector: {
      type: Boolean,
      default: false,
    },
    isCollection: {
      type: Boolean,
      default: false,
    },
    isPDP: {
      type: Boolean,
      default: false,
    },
    isProductPreview: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    isMainPriceOnPDP: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { getFormattedPrice } = usePriceFormat();
    const pumPositionColumn = ref(2);
    const mustShowRecommendedPrice = inject('mustShowRecommendedPrice', false);
    const hasSalePrice = computed(() => {
      return (
        props.item &&
        'unit_sale_price' in props.item &&
        props.item.unit_sale_price !== null &&
        props.item.unit_sale_price !== undefined
      );
    });

    const hasRecommendedPrice = computed(() => {
      return Boolean(props.item.discount && mustShowRecommendedPrice === true && props.item.unit_price);
    });

    const setNumberOfRows = computed(() => {
      if (props.isPDP) {
        if (hasRecommendedPrice.value) {
          return '1rem 1rem';
        }
        return '1rem';
      }
      return 'min-content min-content';
    });

    const setNumberOfColumns = computed(() => {
      if (props.isPDP && props.item.pumOrPackComposition && props.hasPriceRange && hasRecommendedPrice.value) {
        pumPositionColumn.value = 3;
        return 'max-content max-content 1fr';
      }
      if (props.item.pumOrPackComposition && props.hasPriceRange) {
        pumPositionColumn.value = 3;
      }
      if (props.isPDP && !props.hasPriceRange && props.item.pumOrPackComposition && !hasRecommendedPrice.value) {
        if (props.isMainPriceOnPDP) {
          pumPositionColumn.value = 2;
          return 'max-content 1fr';
        }
        pumPositionColumn.value = 1;
        return '1fr';
      }
      if (!props.isProductPreview && !props.hasPriceRange && props.item.pumOrPackComposition && !hasRecommendedPrice.value) {
        pumPositionColumn.value = 1;
        return '1fr';
      }
      return 'max-content 1fr';
    });

    const setGAP = computed(() => {
      if (props.isProductPreview) {
        return '0.5rem';
      }
      if (props.hasPriceRange || props.item.pumOrPackComposition) {
        return '0.5rem';
      }
      return '0';
    });

    const setMarginBottom = computed(() => {
      if (hasRecommendedPrice) {
        return '0.3rem';
      } else {
        return '0';
      }
    });

    const mainPriceStyle = computed(() => {
      return {
        ['price-sale']: !mustShowRecommendedPrice,
        ['price-regular']: mustShowRecommendedPrice,
      };
    });

    const formattedUnitPrice = computed(() => getFormattedPrice(props.item.unit_price, props.isCollection));
    const formattedUnitSalePrice = computed(() => getFormattedPrice(props.item.unit_sale_price, props.isCollection));

    const formattedPriceWithoutDiscount = computed(() => {
      if (hasSalePrice.value) return formattedUnitSalePrice.value;
      return formattedUnitPrice.value;
    });
    const priceWithoutDiscount = computed(() => {
      if (hasSalePrice.value) return props.item.unit_sale_price;
      return props.item.unit_price;
    });

    return {
      formattedPriceWithoutDiscount,
      formattedUnitPrice,
      formattedUnitSalePrice,
      hasRecommendedPrice,
      mainPriceStyle,
      mustShowRecommendedPrice,
      PriceTypes,
      priceWithoutDiscount,
      pumPositionColumn,
      setGAP,
      setMarginBottom,
      setNumberOfColumns,
      setNumberOfRows
    };
  },
};
</script>

<style lang="less" scoped>
@import 'CommonLessFiles/variables/screen.less';
@import 'LessFiles/fontsSizes.less';

.price-range-with-recommended {
  color: black;
}

.product_preview-buy .price-with-recommended {
  margin-bottom: v-bind('setMarginBottom');
}

@media @desktop {
  .price-with-recommended {
    display: grid;
    grid-template-columns: v-bind('setNumberOfColumns');
    grid-template-rows: v-bind('setNumberOfRows');
    grid-gap: v-bind('setGAP');
    grid-row-gap: 0.5rem;
    .price-pum {
      grid-column: v-bind('pumPositionColumn');
    }
    .price-unit--normal {
      padding-right: 0;
    }
    .price-recommended {
      grid-column: span 3;
    }
    .price-regular {
      padding-right: 0;
    }
    .price--range {
      margin-right: 0;
    }
  }

  .product_detail-aside--price_color_selector {
    gap: 0.5rem;
    line-height: 1rem;
    .price-pum {
      grid-row: 1;
      grid-column: v-bind('pumPositionColumn');
    }
  }
}

@media @mobile {
  .product_detail_mobile-aside {
    .price-with-recommended {
      margin-bottom: 0.5rem;
    }
  }
}

.size-element_extra-info {
  .price {
    .price-with-recommended {
      display: grid;
      grid-row-gap: 0.2rem;
      grid-template-columns: v-bind('setNumberOfColumns');
      grid-template-rows: v-bind('setNumberOfRows');
      .price-container {
        justify-content: flex-end;
      }
      .price-pum {
        grid-row: 2;
        grid-column: v-bind('pumPositionColumn');
      }
      .price-unit--normal {
        padding-right: 0;
      }
    }
  }
}
.product_ticket-price {
  padding-left: 0.5em;
  margin-top: -1em;
}
</style>
